.videoBtn {
    cursor: pointer;
    position: absolute;
    top: 10%;
    left: 10%;
  }
  .headSocial {
    width: 50px;
    height: 40px;
    display: inline-block;
  }
  #showVideoOverlay {
    display: none;
  }
  .videoOverlay {
    display: none;
  }
  #showVideoOverlay:checked ~ .videoOverlay {
    content: "";
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    margin: 0;
    padding: 0;
    z-index: 1;
    
    & .videoOverlayContainer {
      position: fixed;
      width: 70%;
      box-shadow: 0 0 5px;
      background: $white;
      padding: 1rem;
      box-sizing: border-box;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
  
      @include media-breakpoint-down(md) {
        max-width: 100%;
        width: 95%;
      }
  
      & .videoOverlay-close {
        cursor: pointer;
        &:before {
          font-size: 1.2rem;
        }
      }
    }
  }