.cookies {
  position: fixed;
  z-index: 9999;
  bottom: 30px;
  left: 30px;
  right: 30px;

  .btn {
    color: $white;
    background-color: $green;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid $green;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  }
  [type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
  }
}

.partnerLogo {
  img {
    height: 50px;
    display: inline-block;
    margin-right: 2rem;
  }
}

.footer {
  a {
    &:hover {
        text-decoration: underline;
    }
  }

  .sitemap {
    &__free-text {
      & > * {
        font-size: sh-font-size('xsmall');
      }

      a {
        @extend .sitemap__link;
        color: sh-black(40);

        &:hover {
          color: white;
        }
      }
    }
  }
}
