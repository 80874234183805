.wysiwyg {
  white-space: normal;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    white-space: normal;
    margin: 0 0 1.66667rem 0;
  }
}
