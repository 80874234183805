.navigation-bar {
    background-color: #ededed;
    box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);
}

.navigation-bar__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
  height: 50px;
  padding-right: 0.833rem;
  padding-left: 0.833rem;
}

.navigation-bar__panel {
    background-color: #ededed;
}

.navigation-bar__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navigation-bar__app-identifier {
  font-size: 0.8889rem;
  color: #1a1a1a;
}

.navigation-bar__toggle-button {
  height: 44px;
  margin-right: 1.11111rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigation-bar__list {
  display: none;
  list-style: none;
  background-color: #ededed;
}

.navigation-bar__item {
    display: block;
    margin: 0;
    padding: 0;
}

.navigation-bar__link {
    color: #666666;
    display: block;
    position: relative;
    margin: 0;
    padding: 0.55556rem 1.11111rem 0.55556rem;
    font-size: 0.88889rem;
    line-height: 1.66667rem;
    font-weight: 600;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    box-shadow: none;
    white-space: nowrap;
    &.is-active {
        color: #1a1a1a;
        border: 1px solid #aaa;
        border-radius: 4px;
        &:hover {
            border-color: #1a1a1a;
        }
    }
}

@media only screen and (max-width: 47.94444em) {
    .navigation-bar.is-open {
        .navigation-bar__container {
            position: fixed;
            z-index: 2222;
            background-color: #ededed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .navigation-bar__panel {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);
            z-index: 2223;
        }
        .navigation-bar__wrapper {
            margin: 0 auto;
            max-width: 100%;
            padding-right: .833rem;
            padding-left: .833rem;
        }
        .navigation-bar__list {
            position: absolute;
            top: 44px;
            left: 0;
            width: 100%;
            bottom: 0;
            display: block;
            padding-top: 0.83333rem;
        }
        .navigation-bar__item {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            // padding-right: 0.83333rem;
            // padding-left: 1.83332rem;
        }
        .navigation-bar__link {
            font-size: 0.8889rem;
        }
        .navigation-bar__link.is-active {
            border: none;
        }
    }

}

@media only screen and (min-width: 48em) {
    .navigation-bar__toggle-button {
        display: none;
    }

    .navigation-bar__app-identifier {
        display: none;
    }

    .navigation-bar__list {
        display: flex;
        white-space: nowrap;
        margin: 0 -1.11111rem;
        padding: 0;
    }

    .navigation-bar__container {
        margin: 0 0 0 15px;
    }
}

// @media only screen and (min-width: 36em) {
//     .navigation-bar__container {
//         width: 30em;
//     }
// }
@media only screen and (max-width: 62em) {
    .navigation-bar__container {
        // width: 46em;
        width: 100%;
        // margin: 0 0 0 15px;
    }
}
@media only screen and (min-width: 62em) {
    .navigation-bar__container {
        width: 57.5em;
    }
}
@media only screen and (min-width: 75em) {
    .navigation-bar__container {
        width: 69em;
    }
}