// helper

.margin-top-0_25 { margin-top: .4266675rem !important; }
.margin-bottom-0_25 { margin-bottom: .4266675rem !important; }
.margin-top-0_5 { margin-top: .833335rem !important; }
.margin-bottom-0_5 { margin-bottom: .833335rem !important; }
.margin-left-0_5 { margin-left: .833335rem !important; }
.margin-right-0_5 { margin-right: .833335rem !important; }
.padding-0_5 { padding: .833335rem !important; }
.padding-top-0_5 { padding-top: .833335rem !important; }
.padding-bottom-0_5 { padding-bottom: .833335rem !important; }
.fix-padding-68 { padding-bottom: 68%; }
.font-weight-bold, .text-bold { font-weight: bold; }
.border-none {
  border: none;
}

// General

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {
  .container {
    max-width:540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width:720px
  }
}

@media (min-width: 992px) {
  .container {
    max-width:960px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width:1140px
  }
}

.container-fluid,.container-lg,.container-md,.container-sm,.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {
  .container,.container-sm {
    max-width:540px
  }
}

@media (min-width: 768px) {
  .container,.container-md,.container-sm {
    max-width:720px
  }
}

@media (min-width: 992px) {
  .container,.container-lg,.container-md,.container-sm {
    max-width:960px
  }
}

@media (min-width: 1200px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {
    max-width:1140px
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.no-gutters {
  margin-right: 0;
  margin-left: 0
}

.section {
  padding: 4rem 0;
}

.speakers {
  padding: 4rem 0;

  .section {
    padding: 0;
  }
}

.button {
  border-radius: 2px;
  cursor: pointer;
  
  &:before, 
  & i:before {
    color: $green;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba($green, 0.5);
  }

  &.success {
    background-color: $green;
    color: $green-light;
    pointer-events: none;
  }
  &.error {
    background-color: orange;
  }
}

.button--green {
  background-color: $green;
  border-color: $green;
  color: $white;
  &:before {
    color: $white;
  }
  &:hover {
    background-color: darken($green, 5);
    border-color: darken($green, 5);
  }
}

// .bg-color-black {
//   .sc-link {
//     background-color: sh-black(100);
//     opacity: .4;
//   }
// }

.sc-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.75) !important;
  border: none;
  width: 3.5rem !important;
  height: 3.5rem !important;
  border-radius: 50%;
  cursor: pointer;
  &:before {
    color: white;
    font-size: 1.9rem;
  }
  &:hover {
    background-color: rgba(0,0,0,.6) !important;  
  }
  .arrow {
    transform: rotate(45deg);
    width: 2.5rem;
    height: 2.5rem;
  }
}

.burger {
  --size: 1rem;
  --pad: 0.06rem;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  display: flex;
  flex-direction: column;
  /* Make sure top/bottom lines are at the top/bottom of the button */
  justify-content: space-between;
  /* Lines should be less spaced out (padding squishes them together) */
  padding: var(--pad) 0;
  margin-right: 10px;
  border: 0;
  border-radius: 0;
  background: none;

  & > div {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: currentcolor;
    transition: transform 0.2s, opacity 0.2s;
    /* Half the internal size of the button (i.e. height without padding) */
    --half: calc((var(--size) - (var(--pad) * 2)) / 2);
  }
}


.d-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: sh-black(80);
  opacity: .75;
  z-index: 9999;
}

// App
.App {
  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }
}

// Topbar
.logoImg {
  height: 80px;
  background: transparent;
}

.attendBtn {
  font-size: 1.3rem;
  padding: 0.65556rem 1.31111rem 0.65556rem;
}

.hero-block {
  height: 100%;
  position: relative;
}
.hero-block__title {
  margin-top: 2rem;
}

.progressbar__container {
  width: 100%;
  height: 4px;
  .progressbar__indicator {
    height: 100%;
  }
}

.news {
  margin-top: 2rem;
}

.bg-color-black .label {
  color: sh-color('gray-shade-4');
}

@media only screen and (min-width: 56.94444em) {
  .hide-for-medium-up {
    display: none !important;
  }
}
@media only screen and (max-width: 56.94444em) {
  .show-for-medium-up {
    display: none !important;
  }
}

.label {
  margin-right: 5px;

  &--has-outline {
    background-color: sh-color('white');
    box-shadow: rgb(112, 112, 112) 0px 0px 0px 1px inset;
    color: sh-black(100);
  }
}

.live__label {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  padding: 6px 10px 6px 20px;
  font-size: to-rem(14);

  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #fff;
    left: 7px;
    top: 50%;
    opacity: 1;
    transform: translateY(-50%);
    border-radius: 50%;
    animation: live-animation ease 1s alternate infinite;
  }
}

@keyframes live-animation {
  to {
    opacity: 0.7;
  }
}

.schedule-object {
  * {
    font-size: 0.8889rem !important;
    line-height: 1.38889rem !important;
  }

  thead th {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  th {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .schedule-slot {
    box-shadow: inset 0 0 0 3px white;
    background-color: #ededed;
    vertical-align: middle;

    &.schedule-slot--active {
      background-color: #7a162d;
      color: #fff;
      text-align: center;
    }
  }
}

// Inpage Navigation
.inpage-navigation {
  margin: 0;
  box-shadow: 0 0 5px rgba(0,0,0,.25);
}
.inpage-navigation__panel {
  align-content: flex-start;
  border: 0 solid transparent;

  .inpage-navigation__selectbox {
    .selectbox__select {
      padding: 1.11111rem 1.111111rem;
    }
  }

  .inpage-navigation__list {
    padding: .5rem 0;
  }

  .inpage-navigation__link.is-active {
    color: $green;
    
    &:after {
      border: transparent;
    }
  }
}

.sticky-element {
  position: relative;
  background-color: #ffffff;
  z-index: 9998;
}

.collection {
  border-top: none;

  &__item {
    flex-direction: column;
    align-items: flex-start;

    h5 {
      margin-bottom: 0.55556rem;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &:last-child {
      p:last-child {
        margin-bottom: 1.66667rem;
      }
    }
  }
}

// Footer

footer {
  a {
    text-decoration: underline;
  }
}

.custom-footer {
  & > .container > .row > .col > div > p {
    display: inline-block;
  }
  img {
    display: inline-block;
    max-width: 200px;
    max-height: auto;
    margin-right: 1rem;
    vertical-align: top;
  }
}