html{
    font-size: 16px;
    font-family:"freight-sans-pro", sans-serif;
    line-height:1.15;
    -webkit-text-size-adjust:100%;
    -webkit-tap-highlight-color:rgba(0,0,0,0)
}

body{
    margin:0;
    font-family:"freight-sans-pro", sans-serif;
    font-size:16px;
    font-size:1rem;
    font-weight:400;
    line-height:1.5;
    color:#212529;
    text-align:left;
    background-color:#fff
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{
    margin-bottom:.5rem;
    font-family:"freight-sans-pro", sans-serif;
    font-weight:500;
    line-height:1.4
}
.h1,h1{
    font-size:39px;
    font-size:2.44rem;
    color: $green;
}
@media (max-width:1200px){
    .h1,h1{
        font-size:calc(1.5rem + 3vw)
    }
}
.h2,h2{
    font-size:36px;
    font-size:2.25rem
}
@media (max-width:1200px){
    .h2,h2{
        font-size:calc(1.35rem + 1.2vw)
    }
}
.h3,h3{
    font-size:21.008px;
    font-size:1.313rem
}
@media (max-width:1200px){
    .h3,h3{
        font-size:calc(1.2563rem + .0756vw)
    }
}
.h4,h4{
    font-size:24px;
    font-size:1.5rem
}
@media (max-width:1200px){
    .h4,h4{
        font-size:calc(1.275rem + .3vw)
    }
}
.h5,h5{
    font-size:20px;
    font-size:1.25rem
}
.h6,h6{
    font-size:16px;
    font-size:1rem
}

.white, .color-white, .text-white {
    a {
        color: $white;
        &:hover {
            color: #cac4c4!important;
        }
    }
}

ul, ol, li {
    font-size: 1.1rem;
}

p, .p1, .p2, .p3 {
    font-family: "freight-sans-pro", sans-serif;
}

p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: .5rem;
}

.p1 {
    font-size: 1rem;
    line-height: 1.5rem;
}


.p2 {
    font-weight: bold;
}

.p2, .p3, .font-size-small {
    font-size: 1rem;
    line-height: 1.5rem;
}

a {
    &:hover {
        color: $green;
    }
    &:focus {
        box-shadow: 0 0 0 2px rgba($green, .5);
    }
}

// enable line-breaks for textareas
h1,
h2,
h3,
h4,
h5,
h6,
p {
    white-space: pre-line;
}