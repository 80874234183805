// Header
header {
  padding: 0 !important;
  // min-height: 60vh;

  .hero-block {
    max-width: 1920px;
    width: 100%;
  }

  .headImg {
    width: 100%;
  }
}

.header--cards {
  .card {
    color: white;
  }

  a.card {
    &:hover {
      color: white;
    }
  }

  div.card {
    .card__image {
      cursor: default;
    }
  }
}