a.card {
  border-radius: 0;
}

// SessionCard
.card__image {
  // border: 1.5px solid $background;
}
.card__image:not(.speaker__img) {
  cursor: pointer;
}

//custom card
@include media-breakpoint-up(md) {
  .card__custom--horizontal {
    flex-direction: row;
    margin-bottom: 1rem;
    width: 100%;
    .card__image {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .card__body {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
      padding: 0 1.66667rem !important;
      margin-top: 0;
    }
  }
}