$white: #fff;
$black: #00171F;
$green-light: #31475E;
$green: #253F5A;
$background: #F8FAFD;
$bg-icons: #eeecec;
$dark: #1a1d23;
$light: #F3F3F4;
$grey: #475362;
$blue: #31475E;
$darkblue: #253F5A;
$font: #05446F;

.white, .color-white, .text-white {
    color: $white !important;
}
.light, color-light, .text-light {
    color: $light !important;
}
.black, .color-black, .text-black {
    color: $black!important;
}
.green-light, .color-green-light, .text-green-light {
    color: $green-light!important;
}
.green, .color-green, .text-green {
    color: $green!important;
}
.bg-icons {
    color: $bg-icons!important;
}
.dark, .color-dark, .text-dark {
    color: $dark!important;
}

.bg-color-font, .bg-font {
    background-color: $font!important;
}
.bg-color-white, .bg-white {
    background-color: $white!important;
}
.bg-color-light, .bg-light {
    background-color: $light!important;
}
.bg-color-black, .bg-black {
    background-color: $black!important;
}
.bg-color-green-light, .bg-green-light {
    background-color: $green-light!important;
}
.bg-color-green, .bg-green {
    background-color: $green!important;
}
.bg-color-background, .bg-background {
    background-color: $background!important;
}
.bg-color-dark, .bg-dark {
    background-color: $grey!important;
}

.bg-color-blue, .bg-blue {
    background-color: $blue!important;
}
.bg-color-darkblue, .bg-darkblue {
    background-color: $darkblue!important;
}