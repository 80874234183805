// Detailed view

// .details-background {
//   display: none;
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   width: 100%;
//   height: 100vh;
//   background-color: sh-black(80);
//   opacity: .75;
//   z-index: 9999;
// }

.details {
  position: fixed;
  top: 0;
  right: 0;
  z-index: sh-z-index('tooltip');
  background-color: sh-color('white');
  height: 100%;
  width: 50%;
  max-width: 60%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10), 0 -1px 4px 0 rgba(0,0,0,0.05);

  &__content {
    position: relative;
    padding: 1rem;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
  }

  // Handle animations
  // https://reactcommunity.org/react-transition-group/css-transition
  &Animation {
    &-enter {
      right: -100%;
    }
    
    &-enter-done {
      right: 0;
      transition: right 250ms ease-in-out;
    }
    
    &-exit {
      right: 0;
    }

    &-exit-active {
      right: -100%;
      transition: right 250ms ease-in-out;
    }
    
    &-exit-done {
      right: -100%;
    }
  }

  &.is-open {
    // transform: translateX(0);
  }

  &__title {
    margin-top: 1.944rem;
  }

  &-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    min-width: unset;
    background-color: #fff;
    z-index: 1;
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 56.94444em) {
  .details__content {
    padding: 1rem 3rem 1rem 1rem;
  }
}