/* source-sans-regular - latin */
@font-face {
    font-family: 'Source Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/source-sans/source-sans-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Regular'), local('SourceSans-Regular'),
         url('../fonts/source-sans/source-sans-pro-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/source-sans/source-sans-pro-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/source-sans/source-sans-pro-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/source-sans/source-sans-pro-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/source-sans/source-sans-pro-v14-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-600 - latin */
@font-face {
    font-family: 'Source Sans';
    font-style: bold;
    font-weight: 600;
    src: url('../fonts/source-sans/source-sans-pro-v14-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Bold'), local('SourceSans-Bold'),
         url('../fonts/source-sans/source-sans-pro-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/source-sans/source-sans-pro-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/source-sans/source-sans-pro-v14-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/source-sans/source-sans-pro-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/source-sans/source-sans-pro-v14-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-600-italic - latin */
@font-face {
    font-family: 'Source Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/source-sans/source-sans-pro-v14-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Bold'), local('SourceSans-Bold'),
         url('../fonts/source-sans/source-sans-pro-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/source-sans/source-sans-pro-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/source-sans/source-sans-pro-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/source-sans/source-sans-pro-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/source-sans/source-sans-pro-v14-latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* alegreya-700 - latin */
@font-face {
    font-family: 'Alegreya';
    font-style: bold;
    font-weight: 700;
    src: url('../fonts/alegreya/alegreya-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Patua One'), local('PatuaOne-Regular'),
         url('../fonts/alegreya/alegreya-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/alegreya/alegreya-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/alegreya/alegreya-v15-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/alegreya/alegreya-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/alegreya/alegreya-v15-latin-700.svg#Alegreya') format('svg'); /* Legacy iOS */
  }