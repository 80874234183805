.loader {
  @extend .container;
  text-align: center;
  margin: 3rem auto;

  &__spinner {
    display: inline-block!important;
    border: 16px solid #5c84af;
    border-top: 16px solid $green;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
